<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">Pianificazione Data e Orario Invio</h5>
      <div class="row">
        <div class="col-md-6">
          <ValidationProvider
            name="datePicker"
            rules="required"
            v-slot="{ failed }"
          >
            <!-- <h4 class="title">Date Picker</h4> -->
            <fg-input label="Data di inzio"
                      :error="failed ? 'Il campo Data di Inzio è obbligatorio': null">
              <el-date-picker v-model="form.datePicker" type="date" placeholder="Seleziona Data di inzio"
                              :picker-options="datePickerOptions">
              </el-date-picker>
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider
            name="descrizione"
            rules="required"
            v-slot="{ failed }"
          >
             <fg-input label="Orario di Invio"
                      :error="failed ? 'Il campo Orario è obbligatorio': null">
              <el-time-select
                v-model="form.timePicker"
                :picker-options="{
                  start: '00:00',
                  step: '01:00',
                  end: '23:00'
                }"
                placeholder="Seleziona un Orario">
              </el-time-select>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6 col-md-6">
          <fg-input>
            <checkbox v-model="form.hasRecursion"
                      name="isAll">Spunta se vuoi che gli invii si ripetano
            </checkbox>
          </fg-input>
        </div>
      </div>
      <div class="row" v-if="form.hasRecursion === true">
        <div class="col-xs-12 col-md-6">
           <ValidationProvider
            name="iteration"
            rules="required"
            v-slot="{ failed, passed }"
          >
            <fg-input name="iteration" label="Intervallo di ripetizione" :error="failed ? 'Il campo Intervallo di ripetizione è obbligatorio': null" :hasSuccess="passed">
              <el-input-number v-model="form.interval"
                  placeholder="Intervallo ripetizione (obbligatorio)"
                  name="interval">
              </el-input-number>
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="col-xs-12 col-md-6">
          <ValidationProvider
            name="templates"
            rules="required"
            v-slot="{ failed }"
          >
          <fg-input label="Unità di misura"
                    :error="failed ? 'Il campo Unità di misura è obbligatorio': null">
            <el-select name="templates"
                       class="select-default"
                       v-model="form.udm">
              <el-option v-for="tmp in udm_options"
                         :key="tmp.value"
                         class="select-default"
                         :label="tmp.label"
                         :value="tmp.value">
              </el-option>
            </el-select>
          </fg-input>
          </ValidationProvider>
        </div>
      </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";
  import {Checkbox} from 'src/components/index'
  import { DatePicker, TimeSelect, Slider, Select, Option } from 'element-ui'
  import {
    FormGroupInput as FgInput
  } from 'src/components/index'

  extend("required", required);
  extend("email", email);

  export default {
    data() {
      return {
        form: {
          datePicker: '',
          timePicker: ''
        },
        datePickerOptions: {
          shortcuts: [{
            text: 'Oggi',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: 'Domani',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: 'Fra una settimana',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }]
        },
        udm_options: [
            { label: 'Giorni',    value: 'd', },
            { label: 'Settimane', value: 'w', },
            { label: 'Mesi',      value: 'M', },
            { label: 'Anni',      value: 'y', },
        ],
      };
    },
    components: {
      Checkbox,
      FgInput,
      [DatePicker.name]: DatePicker,
      [TimeSelect.name]: TimeSelect,
      [Slider.name]: Slider,
      [Option.name]: Option,
      [Select.name]: Select
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          this.$emit('on-validated', res)
          return res
        })
      }
    }
  }
</script>
<style>
</style>

<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-sm-8">
        <div class="card card-wizard" id="wizardCard">
          <form-wizard shape="tab"
                       @on-complete="wizardComplete"
                       error-color="#FB404B"
                       color="#35495E"
                       title="Programmazione Invio"
                       subtitle="Programma l'invio di un tuo sollecito">
            <tab-content title="Impostazioni Invio"
                         class="col-12"
                         :before-change="() => validateStep('firstStep')"
                         icon="nc-icon nc-ruler-pencil">
              <first-step ref="firstStep" @on-validated="onStepValidated"></first-step>
            </tab-content>

            <tab-content title="Date e Orario"
                         class="col-12"
                         :before-change="() => validateStep('secondStep')"
                         icon="nc-icon nc-time-alarm">
              <second-step ref="secondStep" @on-validated="onStepValidated"></second-step>
            </tab-content>
            <tab-content title="Ultimo step"
                         class="col-12"
                         icon="nc-icon nc-check-2">
              <b-overlay
                id="overlay-background"
                :show="!hasLoaded"
                fixed
                no-wrap
                variant="light"
                opacity="0.85"
                blur="5px"
              >
              </b-overlay>
              <div>
                <h2 class="text-center text-space">Fine!
                  <br>
                  <small>Clicca su "<b>Fine</b>" per pianificare un nuovo Invio</small>
                </h2>
              </div>

            </tab-content>

            <button slot="prev" class="btn btn-default btn-fill btn-wd btn-back">Back</button>
            <button slot="next" class="btn btn-default btn-fill btn-wd btn-next">Next</button>
            <button slot="finish" class="btn btn-success btn-fill btn-wd">Fine</button>
          </form-wizard>
          <div class="row custom_error" v-if="error">
            <p style="color:red;">{{ error }} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import FirstStep from './Wizard/FirstStep.vue'
  import SecondStep from './Wizard/SecondStep.vue'
  import ReminderService from 'src/services/ReminderService'
  import Swal from 'sweetalert2'
  const moment = require('moment')

  export default {
    data () {
      return {
        hasLoaded: true,
        error: null,
        wizardModel: {}
      }
    },
    components: {
      FormWizard,
      TabContent,
      FirstStep,
      SecondStep,
    },
    methods: {
      validateStep(ref) {
        return this.$refs[ref].validate()
      },
      onStepValidated(validated, model) {
        this.wizardModel = {...this.wizardModel, ...model}
      },
      async wizardComplete() {
        try {
          this.hasLoaded = false
          var firstData = this.$refs['firstStep'].form
          var secondData = this.$refs['secondStep'].form
          var isGroup = true
          var isAll = false

          var objToSend = {}

          objToSend.group_id = firstData.group
          objToSend.company_id = firstData.company
          objToSend.template_id = firstData.template
          isGroup = firstData.isGroup
          isAll = firstData.isAll

          objToSend.schedule = secondData.timePicker
          objToSend.start_date = moment(new Date(secondData.datePicker)).format('YYYY-MM-DD')
          console.log( 'secondData', secondData );
          if ( secondData.hasRecursion === true ) {
              objToSend.recursion = {
                  interval : secondData.interval,
                  udm      : secondData.udm,
              };
          }
          console.log('E giusto?', objToSend)

          const reminder = await ReminderService.createReminder(objToSend, isGroup, isAll)
          if (reminder) {
            console.log('reminder create', reminder)
          }
        } catch(err) {
          console.log('err', err)
          this.error = err
        } finally {
          this.hasLoaded = true
          if (!this.error) {
            Swal.fire({
              title: "Reminder creato correttamente",
              type: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false
            });
            setTimeout(() => {
              this.$router.push({
                path: '/admin/gestione-reminders'
              })
            })
          }
          
        }
      }
    }
  }
</script>
<style lang="scss">
  .vue-form-wizard .wizard-icon-circle.tab_shape {
    background-color: #9A9A9A !important;
    color: white;
  }
  .vue-form-wizard .wizard-tab-content {
    display: flex; // to avoid horizontal scroll when animating
    .wizard-tab-container {
      display: block;
      animation: fadeIn 0.5s;
    }
  }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v("Pianificazione Data e Orario Invio")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"datePicker","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
return [_c('fg-input',{attrs:{"label":"Data di inzio","error":failed ? 'Il campo Data di Inzio è obbligatorio': null}},[_c('el-date-picker',{attrs:{"type":"date","placeholder":"Seleziona Data di inzio","picker-options":_vm.datePickerOptions},model:{value:(_vm.form.datePicker),callback:function ($$v) {_vm.$set(_vm.form, "datePicker", $$v)},expression:"form.datePicker"}})],1)]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"descrizione","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
return [_c('fg-input',{attrs:{"label":"Orario di Invio","error":failed ? 'Il campo Orario è obbligatorio': null}},[_c('el-time-select',{attrs:{"picker-options":{
                start: '00:00',
                step: '01:00',
                end: '23:00'
              },"placeholder":"Seleziona un Orario"},model:{value:(_vm.form.timePicker),callback:function ($$v) {_vm.$set(_vm.form, "timePicker", $$v)},expression:"form.timePicker"}})],1)]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-6 col-md-6"},[_c('fg-input',[_c('checkbox',{attrs:{"name":"isAll"},model:{value:(_vm.form.hasRecursion),callback:function ($$v) {_vm.$set(_vm.form, "hasRecursion", $$v)},expression:"form.hasRecursion"}},[_vm._v("Spunta se vuoi che gli invii si ripetano\n          ")])],1)],1)]),(_vm.form.hasRecursion === true)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('ValidationProvider',{attrs:{"name":"iteration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var failed = ref.failed;
              var passed = ref.passed;
return [_c('fg-input',{attrs:{"name":"iteration","label":"Intervallo di ripetizione","error":failed ? 'Il campo Intervallo di ripetizione è obbligatorio': null,"hasSuccess":passed}},[_c('el-input-number',{attrs:{"placeholder":"Intervallo ripetizione (obbligatorio)","name":"interval"},model:{value:(_vm.form.interval),callback:function ($$v) {_vm.$set(_vm.form, "interval", $$v)},expression:"form.interval"}})],1)]}}],null,false,3899733888)})],1),_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('ValidationProvider',{attrs:{"name":"templates","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var failed = ref.failed;
return [_c('fg-input',{attrs:{"label":"Unità di misura","error":failed ? 'Il campo Unità di misura è obbligatorio': null}},[_c('el-select',{staticClass:"select-default",attrs:{"name":"templates"},model:{value:(_vm.form.udm),callback:function ($$v) {_vm.$set(_vm.form, "udm", $$v)},expression:"form.udm"}},_vm._l((_vm.udm_options),function(tmp){return _c('el-option',{key:tmp.value,staticClass:"select-default",attrs:{"label":tmp.label,"value":tmp.value}})}),1)],1)]}}],null,false,2354452787)})],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }
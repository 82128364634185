<template>
  <ValidationObserver ref="form">
    <b-overlay
      id="overlay-background"
      :show="!hasLoaded"
      fixed
      no-wrap
      variant="light"
      opacity="0.85"
      blur="5px"
    >
    </b-overlay>
    <form @submit.prevent="validate" v-if="hasLoaded">
      <h5 class="text-center">Impostazioni Invio Sollecito</h5>
      <div class="row">
        <div class="col-md-12">
          <ValidationProvider
            name="templates"
            rules="required"
            v-slot="{ failed }"
          >
          <fg-input label="Sollecito"
                    :error="failed ? 'Il campo Sollecito è obbligatorio': null">
            <el-select name="templates"
                       class="select-default"
                       v-model="form.template">
              <el-option v-for="tmp in templateOptions"
                         :key="tmp.value"
                         class="select-default"
                         :label="tmp.label"
                         :value="tmp.value">
              </el-option>
            </el-select>
          </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row" v-if="true">
        <div class="col-xs-6 col-md-6">
          <fg-input>
            <checkbox v-model="form.isAll"
                      name="isAll">Spunta se vuoi associare il sollecito a tutti gli utenti
            </checkbox>
          </fg-input>
        </div>
        <div class="col-xs-6 col-md-6" v-if="!form.isAll">
          <fg-input>
            <checkbox v-model="form.isGroup"
                      name="isGroup">Spunta se vuoi associare il sollecito a un gruppo
            </checkbox>
          </fg-input>
        </div>
      </div>
      <div class="row" v-if="form.isGroup && !form.isAll">
        <div class="col-md-12">
          <ValidationProvider
            name="group"
            rules="required"
            v-slot="{ failed }"
          >
          <fg-input label="Gruppo"
                    :error="failed ? 'Il campo Gruppo è obbligatorio': null">
            <el-select name="nameGroup"
                       multiple class="select-default"
                       v-model="form.group"
                       :disabled="form.isAll">
              <el-option v-for="group in groupsOptions"
                         :key="group.value"
                         class="select-default"
                         :label="group.label"
                         :value="group.value">
              </el-option>
            </el-select>
          </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row" v-if="!form.isGroup && !form.isAll">
        <div class="col-md-12">
          <ValidationProvider
            name="company"
            rules="required"
            v-slot="{ failed }"
          >
          <fg-input label="Cliente"
                    :error="failed ? 'Il campo Cliente è obbligatorio': null">
            <el-select name="nameCompany"
                       multiple class="select-default"
                       v-model="form.company"
                       :disabled="form.isAll">
              <el-option v-for="company in companiesOptions"
                         :key="company.value"
                         class="select-default"
                         :label="company.label"
                         :value="company.value">
              </el-option>
            </el-select>
          </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row custom_error" v-if="error">
        <p style="color:red;">{{ error }} </p>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { extend } from "vee-validate";
  import { Select, Option } from 'element-ui'
  import { required, email } from "vee-validate/dist/rules";
  import {Checkbox} from 'src/components/index'
  import TemplateService from 'src/services/TemplateService'
  import CompanyService from 'src/services/CompanyService'
  import CustomerService from 'src/services/CustomerService'

  extend("required", required);
  extend("email", email);

  export default {
    data() {
      return {
        form: {
          template: null,
          company: [],
          group: [],
          isGroup: false,
          isAll: false
        },
        templateOptions: [],
        companiesOptions: [],
        groupOptions: [],
        hasLoaded: false,
        error: null
      };
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      Checkbox
    },
    async mounted() {
      try {
        const templates = await TemplateService.getTemplates()
        if (templates) {
          console.log('my templates', templates)
          this.templateOptions = templates.data.map(el => {
            return {
              value: el.id,
              label: el.name
            }
          })
          this.templateOptions = [{value: null, label: 'Seleziona un Sollecito'}].concat(this.templateOptions)
          console.log('templateOptions', this.templateOptions)
          try {
            const companies = await CompanyService.getCompanies()
            if (companies) {
              console.log('companies', companies)
              this.companiesOptions = companies.data.map(el => {
                return {
                  value: el.id,
                  label: el.ragione_sociale ? el.ragione_sociale : el.first_name + " " + el.last_name
                }
              })
              // this.companiesOptions = [{value: null, label: 'Seleziona un Cliente'}].concat(this.companiesOptions)
              console.log('companiesOptions', this.companiesOptions)
              try {
                const groups = await CustomerService.getGroups()
                if (groups) {
                  console.log('groups', groups)
                  this.groupsOptions = groups.data.map(el => {
                    return {
                      value: el.id,
                      label: el.label
                    }
                  })
                  // this.groupsOptions = [{value: null, label: 'Seleziona un Gruppo'}].concat(this.groupsOptions)
                  console.log('groupOptions', this.groupOptions)
                }
              } catch(err) {
                console.log('err', err)
                this.error = 'Errore durante la get di groups'
              }
            }
          } catch(err) {
            console.log('err', err)
            this.error = 'Errore durante la get di companies'
          }
        }
      } catch(err) {
        console.log('err', err)
        this.error = 'Errore durante la get di tempaltes'
      } finally {
        this.hasLoaded = true
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          this.$emit('on-validated', res)
          return res
        })
      }
    }
  }
</script>
<style>
</style>
